import './MyCubes.css';
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { CONTRACT_ADDRESS, transformCubeData } from '../../constants';
import Game from '../../utils/Game.json';
import LoadingIndicator from '../../Components/LoadingIndicator';

const MyCubes = ({ currentAccount, setCurrentAccount, setCubeNFT }) => {
    const [cubes, setCubes] = useState([]);
    const [cubeData, setCubeData] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
        [2, 2, 2, 2, 2, 2, 2, 2, 2],
        [3, 3, 3, 3, 3, 3, 3, 3, 3],
        [4, 4, 4, 4, 4, 4, 4, 4, 4],
        [5, 5, 5, 5, 5, 5, 5, 5, 5]
    ]);
    const [gameContract, setGameContract] = useState(null);
    const [mintingCube, setMintingCube] = useState(false);
    const [colors, setColors] = useState(["green", "blue", "white", "yellow", "red", "orange"]);

    useEffect(() => {
        const { ethereum } = window;

        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const gameContract = new ethers.Contract(
                CONTRACT_ADDRESS,
                Game.abi,
                signer
            );
            setGameContract(gameContract);

        } else {
            console.log('Ethereum object not found');
        }
    }, []);

    useEffect(() => {
        const getCubes = async (sender) => {
            try {
                const tokens = await gameContract.tokensOfOwner(currentAccount);
                setCubes(tokens);
            } catch (error) {
                console.error('Something went wrong while getting params:', error);
            }
        }
        if (gameContract) {
            getCubes();
        }
    }, [gameContract]);

    const renderCubes = () =>
        cubes.map((cube, index) => (
            <div className="col-xl-4 col-md-6 col-12">
                <div className="cube-card">
                    <div className="cube" key={cube} >
                        <div id="cube-container">
                            {renderCube(cube.data, cube.cubeType.toLowerCase())}
                        </div>
                        <div className="mb-4 mt-4">
                            <h5>{cube.name}</h5>
                            <p>Type: <span className={"rarity-" + cube.rarity.toLowerCase()}>{cube.cubeType}</span></p>
                        </div>
                        <button
                            type="button"
                            className="pbtn pbtn-primary"
                            onClick={() => setCubeNFT(cube)}
                        >{`Play with Cube`}</button>
                    </div >
                </div >
            </div >
        )
        );


    function renderSquare(r, c, rarity, cube_data) {
        const parsed_rarity = rarity.toLowerCase();
        if (rarity === "penryn") {
            return (
                <button className={"square " + parsed_rarity + "-" + colors[cube_data[r][c]]} >
                    <span className="penryn-text">
                        I waited almost a year and all I got was this nft
                    </span>
                </button >
            );
        } else {
            return(
                <button className={"square " + parsed_rarity + "-" + colors[cube_data[r][c]]} >
                    <span className="penryn-text">
                    </span>
                </button >
            )
        }
    }

    const renderCube = (data,cube_rarity) =>
        <div id="shape" className="spin">
            <div className="ft">
                <div className="board-row">
                    {renderSquare(0, 0, cube_rarity, data)}
                    {renderSquare(0, 1, cube_rarity, data)}
                    {renderSquare(0, 2, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(0, 3, cube_rarity, data)}
                    {renderSquare(0, 4, cube_rarity, data)}
                    {renderSquare(0, 5, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(0, 6, cube_rarity, data)}
                    {renderSquare(0, 7, cube_rarity, data)}
                    {renderSquare(0, 8, cube_rarity, data)}
                </div>
            </div>
            <div className="rt">
                <div className="board-row">
                    {renderSquare(3, 0, cube_rarity, data)}
                    {renderSquare(3, 1, cube_rarity, data)}
                    {renderSquare(3, 2, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(3, 3, cube_rarity, data)}
                    {renderSquare(3, 4, cube_rarity, data)}
                    {renderSquare(3, 5, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(3, 6, cube_rarity, data)}
                    {renderSquare(3, 7, cube_rarity, data)}
                    {renderSquare(3, 8, cube_rarity, data)}
                </div>
            </div>
            <div className="bk">
                <div className="board-row">
                    {renderSquare(1, 0, cube_rarity, data)}
                    {renderSquare(1, 1, cube_rarity, data)}
                    {renderSquare(1, 2, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(1, 3, cube_rarity, data)}
                    {renderSquare(1, 4, cube_rarity, data)}
                    {renderSquare(1, 5, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(1, 6, cube_rarity, data)}
                    {renderSquare(1, 7, cube_rarity, data)}
                    {renderSquare(1, 8, cube_rarity, data)}
                </div>
            </div>
            <div className="lt">
                <div className="board-row">
                    {renderSquare(2, 0, cube_rarity, data)}
                    {renderSquare(2, 1, cube_rarity, data)}
                    {renderSquare(2, 2, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(2, 3, cube_rarity, data)}
                    {renderSquare(2, 4, cube_rarity, data)}
                    {renderSquare(2, 5, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(2, 6, cube_rarity, data)}
                    {renderSquare(2, 7, cube_rarity, data)}
                    {renderSquare(2, 8, cube_rarity, data)}
                </div>
            </div>
            <div className="tp">
                <div className="board-row">
                    {renderSquare(4, 0, cube_rarity, data)}
                    {renderSquare(4, 1, cube_rarity, data)}
                    {renderSquare(4, 2, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(4, 3, cube_rarity, data)}
                    {renderSquare(4, 4, cube_rarity, data)}
                    {renderSquare(4, 5, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(4, 6, cube_rarity, data)}
                    {renderSquare(4, 7, cube_rarity, data)}
                    {renderSquare(4, 8, cube_rarity, data)}
                </div>
            </div>
            <div className="bm">
                <div className="board-row">
                    {renderSquare(5, 0, cube_rarity, data)}
                    {renderSquare(5, 1, cube_rarity, data)}
                    {renderSquare(5, 2, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(5, 3, cube_rarity, data)}
                    {renderSquare(5, 4, cube_rarity, data)}
                    {renderSquare(5, 5, cube_rarity, data)}
                </div>
                <div className="board-row">
                    {renderSquare(5, 6, cube_rarity, data)}
                    {renderSquare(5, 7, cube_rarity, data)}
                    {renderSquare(5, 8, cube_rarity, data)}
                </div>
            </div>
        </div>


    return (
        <div className="select-character-container text-center">
            <div className="row justify-content-center">
                {renderCubes()}
            </div>
        </div>
    );
};

export default MyCubes;
