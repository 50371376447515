

function Square(props) {
    return (
        <button className={"square " + props.color} onClick={props.onClick}>

        </button>
    );
}

class Cube {
    constructor(props) {
        this.state = {
            data: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [1, 1, 1, 1, 1, 1, 1, 1, 1],
                [2, 2, 2, 2, 2, 2, 2, 2, 2],
                [3, 3, 3, 3, 3, 3, 3, 3, 3],
                [4, 4, 4, 4, 4, 4, 4, 4, 4],
                [5, 5, 5, 5, 5, 5, 5, 5, 5]
            ],
            colors: {
                0: "white",
                1: "yellow",
                2: "green",
                3: "blue",
                4: "red",
                5: "orange"
            },
            colorSideMapping: {
                0: "F",
                1: "B",
                2: "R",
                3: "L",
                4: "D",
                5: "U"
            },
            turns: [],
            x_flip: true,
            y_flip: true
        }
    }

    handleClick(side) {
        // rotates a side clockwise
        let newCube = this.state.data.map(a => Object.assign({}, a));
        let oldTurns = this.state.turns;

        if (side == 0) {
            rotateFront(newCube, this.state.data);
        } else if (side == 1) {
            rotateBack(newCube, this.state.data);
        } else if (side == 2) {
            rotateRight(newCube, this.state.data);
        } else if (side == 3) {
            rotateLeft(newCube, this.state.data);
        } else if (side == 5) {
            rotateTop(newCube, this.state.data);
        } else if (side == 4) {
            rotateBottom(newCube, this.state.data);
        }

        this.setState(
            {
                data: newCube,
                turns: oldTurns.concat(side)
            }
        );
    }

    rotate(rotation) {
        let newCube = this.state.data.map(a => Object.assign({}, a));
        let oldTurns = this.state.turns;

        if (rotation == "F") {
            rotateFront(newCube, this.state.data);
        } else if (rotation == "B") {
            rotateBack(newCube, this.state.data);
        } else if (rotation == "R") {
            rotateRight(newCube, this.state.data);
        } else if (rotation == "L") {
            rotateLeft(newCube, this.state.data);
        } else if (rotation == "U") {
            rotateTop(newCube, this.state.data);
        } else if (rotation == "D") {
            rotateBottom(newCube, this.state.data);
        } else if (rotation == "F'") {
            newCube = rotateFront(newCube, this.state.data);
            newCube = rotateFront(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateFront(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "B'") {
            newCube = rotateBack(newCube, this.state.data);
            newCube = rotateBack(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateBack(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "R'") {
            newCube = rotateRight(newCube, this.state.data);
            newCube = rotateRight(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateRight(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "L'") {
            newCube = rotateLeft(newCube, this.state.data);
            newCube = rotateLeft(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateLeft(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "U'") {
            newCube = rotateTop(newCube, this.state.data);
            newCube = rotateTop(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateTop(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "D'") {
            newCube = rotateBottom(newCube, this.state.data);
            newCube = rotateBottom(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateBottom(newCube, newCube.map(a => Object.assign({}, a)));
        }

        this.setState(
            {
                data: newCube,
                turns: oldTurns.concat(rotation)
            }
        );
    }

    renderSquare(r, c) {
        return <Square
            value={this.state.data[r][c]}
            onClick={() => this.rotate(this.state.colorSideMapping[r])}
            color={this.state.colors[this.state.data[r][c]]}
        />;
    }

    renderMovementButtons() {
        return (
            <div className="top-null">
                <div className="row">
                    <div className="col-2">
                        <button onClick={() => this.rotate("F")} className="pbtn pbtn-primary">F</button>
                        <button onClick={() => this.rotate("F'")} className="pbtn pbtn-primary">F'</button>
                    </div>
                    <div className="col-2">
                        <button onClick={() => this.rotate("B")} className="pbtn pbtn-primary">B</button>
                        <button onClick={() => this.rotate("B'")} className="pbtn pbtn-primary">B'</button>
                    </div>
                    <div className="col-2">
                        <button onClick={() => this.rotate("R")} className="pbtn pbtn-primary">R</button>
                        <button onClick={() => this.rotate("R'")} className="pbtn pbtn-primary">R'</button>
                    </div>
                    <div className="col-2">
                        <button onClick={() => this.rotate("L")} className="pbtn pbtn-primary">L</button>
                        <button onClick={() => this.rotate("L'")} className="pbtn pbtn-primary">L'</button>
                    </div>
                    <div className="col-2">
                        <button onClick={() => this.rotate("U")} className="pbtn pbtn-primary">U</button>
                        <button onClick={() => this.rotate("U'")} className="pbtn pbtn-primary">U'</button>
                    </div>
                    <div className="col-2">
                        <button onClick={() => this.rotate("D")} className="pbtn pbtn-primary">D</button>
                        <button onClick={() => this.rotate("D'")} className="pbtn pbtn-primary">D'</button>
                    </div>
                </div>
            </div>
        );
    }


    renderCube() {
        return (
            <div id="shape" className={(this.state.x_flip ? "xflip-" : "") + (this.state.y_flip ? "yflip" : "")} >
                <div className="ft">
                    <div className="board-row">
                        {this.renderSquare(0, 0)}
                        {this.renderSquare(0, 1)}
                        {this.renderSquare(0, 2)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(0, 3)}
                        {this.renderSquare(0, 4)}
                        {this.renderSquare(0, 5)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(0, 6)}
                        {this.renderSquare(0, 7)}
                        {this.renderSquare(0, 8)}
                    </div>
                </div>
                <div className="rt">
                    <div className="board-row">
                        {this.renderSquare(3, 0)}
                        {this.renderSquare(3, 1)}
                        {this.renderSquare(3, 2)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(3, 3)}
                        {this.renderSquare(3, 4)}
                        {this.renderSquare(3, 5)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(3, 6)}
                        {this.renderSquare(3, 7)}
                        {this.renderSquare(3, 8)}
                    </div>
                </div>
                <div className="bk">
                    <div className="board-row">
                        {this.renderSquare(1, 0)}
                        {this.renderSquare(1, 1)}
                        {this.renderSquare(1, 2)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(1, 3)}
                        {this.renderSquare(1, 4)}
                        {this.renderSquare(1, 5)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(1, 6)}
                        {this.renderSquare(1, 7)}
                        {this.renderSquare(1, 8)}
                    </div>
                </div>
                <div className="lt">
                    <div className="board-row">
                        {this.renderSquare(2, 0)}
                        {this.renderSquare(2, 1)}
                        {this.renderSquare(2, 2)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(2, 3)}
                        {this.renderSquare(2, 4)}
                        {this.renderSquare(2, 5)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(2, 6)}
                        {this.renderSquare(2, 7)}
                        {this.renderSquare(2, 8)}
                    </div>
                </div>
                <div className="tp">
                    <div className="board-row">
                        {this.renderSquare(4, 0)}
                        {this.renderSquare(4, 1)}
                        {this.renderSquare(4, 2)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(4, 3)}
                        {this.renderSquare(4, 4)}
                        {this.renderSquare(4, 5)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(4, 6)}
                        {this.renderSquare(4, 7)}
                        {this.renderSquare(4, 8)}
                    </div>
                </div>
                <div className="bm">
                    <div className="board-row">
                        {this.renderSquare(5, 0)}
                        {this.renderSquare(5, 1)}
                        {this.renderSquare(5, 2)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(5, 3)}
                        {this.renderSquare(5, 4)}
                        {this.renderSquare(5, 5)}
                    </div>
                    <div className="board-row">
                        {this.renderSquare(5, 6)}
                        {this.renderSquare(5, 7)}
                        {this.renderSquare(5, 8)}
                    </div>
                </div>
            </div>
        );
    }

    renderViewButtons() {
        return (
            <div className="row mt-3">
                <div className="col-6">
                    <button onClick={() => this.setState({ x_flip: !this.state.x_flip })} className="pbtn pbtn-secondary">Rotate View X</button>
                </div>
                <div className="col-6 text-right">
                    <button onClick={() => this.setState({ y_flip: !this.state.y_flip })} className="pbtn pbtn-secondary">Rotate View Y</button>
                </div>
            </div>
        );
    }

    renderTurns() {
        return (
            <div>
                <h3>Turns</h3>
                <hr />
                <div className="turns-container">
                    {this.state.turns}
                </div>
            </div >
        );
    }


    render() {
        return (

            <div>
                <div className="body m-4" onKeyDown={this.process_key_pressed}>
                    <div className="">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <h1>On Chain Cube #1234</h1>
                                        <p>&larr; Your Cubes</p>
                                        <hr></hr>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="left-column-container">

                                            <div id="cube-container">
                                                {this.renderCube()}
                                            </div>
                                            <div className="control-panel mt-4">
                                                {this.renderMovementButtons()}
                                                {this.renderViewButtons()}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <div className="right-column-container turns-panel">
                                            {this.renderTurns()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

class Board {
    render() {
        return (
            <div>
                <Cube />
            </div>
        );
    }
}



function rotateFront(newCube, data) {
    newCube[0][0] = data[0][6];
    newCube[0][1] = data[0][3];
    newCube[0][2] = data[0][0];
    newCube[0][3] = data[0][7];
    newCube[0][4] = data[0][4];
    newCube[0][5] = data[0][1];
    newCube[0][6] = data[0][8];
    newCube[0][7] = data[0][5];
    newCube[0][8] = data[0][2];

    newCube[3][0] = data[4][6];
    newCube[3][3] = data[4][7];
    newCube[3][6] = data[4][8];

    newCube[5][0] = data[3][0];
    newCube[5][1] = data[3][3];
    newCube[5][2] = data[3][6];

    newCube[2][2] = data[5][0];
    newCube[2][5] = data[5][1];
    newCube[2][8] = data[5][2];

    newCube[4][6] = data[2][2];
    newCube[4][7] = data[2][5];
    newCube[4][8] = data[2][8];

    return newCube;
}

function rotateBack(newCube, data) {
    newCube[1][0] = data[1][6];
    newCube[1][1] = data[1][3];
    newCube[1][2] = data[1][0];
    newCube[1][3] = data[1][7];
    newCube[1][4] = data[1][4];
    newCube[1][5] = data[1][1];
    newCube[1][6] = data[1][8];
    newCube[1][7] = data[1][5];
    newCube[1][8] = data[1][2];

    newCube[2][0] = data[4][0];
    newCube[2][3] = data[4][1];
    newCube[2][6] = data[4][2];

    newCube[5][6] = data[2][0];
    newCube[5][7] = data[2][3];
    newCube[5][8] = data[2][6];

    newCube[3][2] = data[5][6];
    newCube[3][5] = data[5][7];
    newCube[3][8] = data[5][8];

    newCube[4][0] = data[3][2];
    newCube[4][1] = data[3][5];
    newCube[4][2] = data[3][8];

    return newCube;
}

function rotateRight(newCube, data) {
    newCube[2][0] = data[2][6];
    newCube[2][1] = data[2][3];
    newCube[2][2] = data[2][0];
    newCube[2][3] = data[2][7];
    newCube[2][4] = data[2][4];
    newCube[2][5] = data[2][1];
    newCube[2][6] = data[2][8];
    newCube[2][7] = data[2][5];
    newCube[2][8] = data[2][2];

    newCube[0][0] = data[4][0];
    newCube[0][3] = data[4][3];
    newCube[0][6] = data[4][6];

    newCube[5][0] = data[0][0];
    newCube[5][3] = data[0][3];
    newCube[5][6] = data[0][6];

    newCube[1][2] = data[5][0];
    newCube[1][5] = data[5][3];
    newCube[1][8] = data[5][6];

    newCube[4][0] = data[1][2];
    newCube[4][3] = data[1][5];
    newCube[4][6] = data[1][8];

    return newCube;
}

function rotateLeft(newCube, data) {
    newCube[3][0] = data[3][6];
    newCube[3][1] = data[3][3];
    newCube[3][2] = data[3][0];
    newCube[3][3] = data[3][7];
    newCube[3][4] = data[3][4];
    newCube[3][5] = data[3][1];
    newCube[3][6] = data[3][8];
    newCube[3][7] = data[3][5];
    newCube[3][8] = data[3][2];

    newCube[1][0] = data[4][8];
    newCube[1][3] = data[4][5];
    newCube[1][6] = data[4][2];

    newCube[5][2] = data[1][6];
    newCube[5][5] = data[1][3];
    newCube[5][8] = data[1][0];

    newCube[0][2] = data[5][2];
    newCube[0][5] = data[5][5];
    newCube[0][8] = data[5][8];

    newCube[4][2] = data[0][2];
    newCube[4][5] = data[0][5];
    newCube[4][8] = data[0][8];

    return newCube;
}

function rotateTop(newCube, data) {
    newCube[5][0] = data[5][6];
    newCube[5][1] = data[5][3];
    newCube[5][2] = data[5][0];
    newCube[5][3] = data[5][7];
    newCube[5][4] = data[5][4];
    newCube[5][5] = data[5][1];
    newCube[5][6] = data[5][8];
    newCube[5][7] = data[5][5];
    newCube[5][8] = data[5][2];

    newCube[0][6] = data[2][6];
    newCube[0][7] = data[2][7];
    newCube[0][8] = data[2][8];

    newCube[3][6] = data[0][6];
    newCube[3][7] = data[0][7];
    newCube[3][8] = data[0][8];

    newCube[1][6] = data[3][6];
    newCube[1][7] = data[3][7];
    newCube[1][8] = data[3][8];

    newCube[2][6] = data[1][6];
    newCube[2][7] = data[1][7];
    newCube[2][8] = data[1][8];

    return newCube;
}

function rotateBottom(newCube, data) {
    newCube[4][0] = data[4][6];
    newCube[4][1] = data[4][3];
    newCube[4][2] = data[4][0];
    newCube[4][3] = data[4][7];
    newCube[4][4] = data[4][4];
    newCube[4][5] = data[4][1];
    newCube[4][6] = data[4][8];
    newCube[4][7] = data[4][5];
    newCube[4][8] = data[4][2];

    newCube[1][0] = data[2][0];
    newCube[1][1] = data[2][1];
    newCube[1][2] = data[2][2];

    newCube[3][0] = data[1][0];
    newCube[3][1] = data[1][1];
    newCube[3][2] = data[1][2];

    newCube[0][0] = data[3][0];
    newCube[0][1] = data[3][1];
    newCube[0][2] = data[3][2];

    newCube[2][0] = data[0][0];
    newCube[2][1] = data[0][1];
    newCube[2][2] = data[0][2];

    return newCube;
}

export default Board;