import React from 'react';
import './LoadingIndicator.css';

const LoadingIndicator = () => {
    return (
        <div>
            <center>
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </center>
        </div>


    );
};

export default LoadingIndicator;
