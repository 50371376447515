import './PlayWithCube.css';
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { CONTRACT_ADDRESS, transformCubeData } from '../../constants';
import Game from '../../utils/Game.json';
import LoadingIndicator from '../../Components/LoadingIndicator';
import Board from '../../Components/CubeBase';

const PlayWithCube = ({ cubeNFT }) => {
    const [gameContract, setGameContract] = useState(null);
    const [colors, setColors] = useState(["green", "blue", "white", "yellow", "red", "orange"]);
    const [colorSideMapping, setColorSideMapping] = useState(["F", "B", "R", "L", "D", "U"]);
    const [sendingState, setSendingState] = useState(false);
    const [rarity, setRarity] = useState("common");
    const [cubeState, setCubeState] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
        [2, 2, 2, 2, 2, 2, 2, 2, 2],
        [3, 3, 3, 3, 3, 3, 3, 3, 3],
        [4, 4, 4, 4, 4, 4, 4, 4, 4],
        [5, 5, 5, 5, 5, 5, 5, 5, 5]
    ]);
    const [stateChanges, setStateChanges] = useState([]);
    const [turns, setTurns] = useState([]);
    const [x_flip, setx_flip] = useState(false);
    const [y_flip, sety_flip] = useState(false);

    useEffect(() => {
        const { ethereum } = window;

        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const gameContract = new ethers.Contract(
                CONTRACT_ADDRESS,
                Game.abi,
                signer
            );

            setGameContract(gameContract);

        } else {
            console.log('Ethereum object not found');
        }
    }, []);

    useEffect(() => {
        const getCubeData = async (sender) => {
            try {
                let cube_data = cubeNFT;
                setCubeState(cube_data.data);
                // set rarity to downcase rarity
                setRarity(cube_data.cubeType.toLowerCase());
                setx_flip(true);
                sety_flip(true);
            } catch (error) {
                console.error('Something went wrong while getting params:', error);
            }
        }
        if (gameContract) {
            getCubeData();
        }
    }, [gameContract]);

    const sendState = (cube_id, data) => async () => {
        try {
            if (gameContract) {
                setSendingState(true);
                console.log('Sending state to contract...');
                console.log(data);
                var parsed_data = [];
                for (var i = 0; i < data.length; i++) {
                    var vals = []
                    for (var j = 0; j < 9; j++) {
                        vals.push(data[i][j]);
                    }
                    parsed_data.push(vals);
                }
                console.log(parsed_data);
                const spinTxn = await gameContract.parseStateChange(cube_id, parsed_data);
                await spinTxn.wait();
                setSendingState(false);
            }
        } catch (error) {
            setSendingState(false);
            console.warn('CubeAction Error:', error);
        }
    };

    function rotateFront(newCube, data) {
        newCube[0][0] = data[0][6];
        newCube[0][1] = data[0][3];
        newCube[0][2] = data[0][0];
        newCube[0][3] = data[0][7];
        newCube[0][4] = data[0][4];
        newCube[0][5] = data[0][1];
        newCube[0][6] = data[0][8];
        newCube[0][7] = data[0][5];
        newCube[0][8] = data[0][2];

        newCube[3][0] = data[4][6];
        newCube[3][3] = data[4][7];
        newCube[3][6] = data[4][8];

        newCube[5][2] = data[3][0];
        newCube[5][1] = data[3][3];
        newCube[5][0] = data[3][6];

        newCube[2][8] = data[5][2];
        newCube[2][5] = data[5][1];
        newCube[2][2] = data[5][0];

        newCube[4][6] = data[2][8];
        newCube[4][7] = data[2][5];
        newCube[4][8] = data[2][2];

        return newCube;
    }

    function rotateBack(newCube, data) {
        newCube[1][0] = data[1][6];
        newCube[1][1] = data[1][3];
        newCube[1][2] = data[1][0];
        newCube[1][3] = data[1][7];
        newCube[1][4] = data[1][4];
        newCube[1][5] = data[1][1];
        newCube[1][6] = data[1][8];
        newCube[1][7] = data[1][5];
        newCube[1][8] = data[1][2];

        newCube[2][0] = data[4][2];
        newCube[2][3] = data[4][1];
        newCube[2][6] = data[4][0];

        newCube[5][6] = data[2][0];
        newCube[5][7] = data[2][3];
        newCube[5][8] = data[2][6];

        newCube[3][2] = data[5][8];
        newCube[3][5] = data[5][7];
        newCube[3][8] = data[5][6];

        newCube[4][0] = data[3][2];
        newCube[4][1] = data[3][5];
        newCube[4][2] = data[3][8];

        return newCube;
    }

    function rotateRight(newCube, data) {
        newCube[2][0] = data[2][6];
        newCube[2][1] = data[2][3];
        newCube[2][2] = data[2][0];
        newCube[2][3] = data[2][7];
        newCube[2][4] = data[2][4];
        newCube[2][5] = data[2][1];
        newCube[2][6] = data[2][8];
        newCube[2][7] = data[2][5];
        newCube[2][8] = data[2][2];

        newCube[0][0] = data[4][0];
        newCube[0][3] = data[4][3];
        newCube[0][6] = data[4][6];

        newCube[5][0] = data[0][0];
        newCube[5][3] = data[0][3];
        newCube[5][6] = data[0][6];

        newCube[1][8] = data[5][0];
        newCube[1][5] = data[5][3];
        newCube[1][2] = data[5][6];

        newCube[4][0] = data[1][8];
        newCube[4][3] = data[1][5];
        newCube[4][6] = data[1][2];

        return newCube;
    }

    function rotateLeft(newCube, data) {
        newCube[3][0] = data[3][6];
        newCube[3][1] = data[3][3];
        newCube[3][2] = data[3][0];
        newCube[3][3] = data[3][7];
        newCube[3][4] = data[3][4];
        newCube[3][5] = data[3][1];
        newCube[3][6] = data[3][8];
        newCube[3][7] = data[3][5];
        newCube[3][8] = data[3][2];

        newCube[1][0] = data[4][8];
        newCube[1][3] = data[4][5];
        newCube[1][6] = data[4][2];

        newCube[5][2] = data[1][6];
        newCube[5][5] = data[1][3];
        newCube[5][8] = data[1][0];

        newCube[0][2] = data[5][2];
        newCube[0][5] = data[5][5];
        newCube[0][8] = data[5][8];

        newCube[4][2] = data[0][2];
        newCube[4][5] = data[0][5];
        newCube[4][8] = data[0][8];

        return newCube;
    }

    function rotateTop(newCube, data) {
        newCube[5][0] = data[5][6];
        newCube[5][1] = data[5][3];
        newCube[5][2] = data[5][0];
        newCube[5][3] = data[5][7];
        newCube[5][4] = data[5][4];
        newCube[5][5] = data[5][1];
        newCube[5][6] = data[5][8];
        newCube[5][7] = data[5][5];
        newCube[5][8] = data[5][2];

        newCube[0][6] = data[2][6];
        newCube[0][7] = data[2][7];
        newCube[0][8] = data[2][8];

        newCube[3][6] = data[0][6];
        newCube[3][7] = data[0][7];
        newCube[3][8] = data[0][8];

        newCube[1][6] = data[3][6];
        newCube[1][7] = data[3][7];
        newCube[1][8] = data[3][8];

        newCube[2][6] = data[1][6];
        newCube[2][7] = data[1][7];
        newCube[2][8] = data[1][8];

        return newCube;
    }

    function rotateBottom(newCube, data) {
        newCube[4][0] = data[4][6];
        newCube[4][1] = data[4][3];
        newCube[4][2] = data[4][0];
        newCube[4][3] = data[4][7];
        newCube[4][4] = data[4][4];
        newCube[4][5] = data[4][1];
        newCube[4][6] = data[4][8];
        newCube[4][7] = data[4][5];
        newCube[4][8] = data[4][2];

        newCube[1][0] = data[2][0];
        newCube[1][1] = data[2][1];
        newCube[1][2] = data[2][2];

        newCube[3][0] = data[1][0];
        newCube[3][1] = data[1][1];
        newCube[3][2] = data[1][2];

        newCube[0][0] = data[3][0];
        newCube[0][1] = data[3][1];
        newCube[0][2] = data[3][2];

        newCube[2][0] = data[0][0];
        newCube[2][1] = data[0][1];
        newCube[2][2] = data[0][2];

        return newCube;
    }

    function rotate(rotation) {
        let newCube = cubeState.map(a => Object.assign({}, a));
        let oldTurns = turns;

        if (rotation == "F") {
            rotateFront(newCube, cubeState);
        } else if (rotation == "B") {
            rotateBack(newCube, cubeState);
        } else if (rotation == "R") {
            rotateRight(newCube, cubeState);
        } else if (rotation == "L") {
            rotateLeft(newCube, cubeState);
        } else if (rotation == "U") {
            rotateTop(newCube, cubeState);
        } else if (rotation == "D") {
            rotateBottom(newCube, cubeState);
        } else if (rotation == "F'") {
            newCube = rotateFront(newCube, cubeState);
            newCube = rotateFront(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateFront(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "B'") {
            newCube = rotateBack(newCube, cubeState);
            newCube = rotateBack(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateBack(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "R'") {
            newCube = rotateRight(newCube, cubeState);
            newCube = rotateRight(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateRight(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "L'") {
            newCube = rotateLeft(newCube, cubeState);
            newCube = rotateLeft(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateLeft(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "U'") {
            newCube = rotateTop(newCube, cubeState);
            newCube = rotateTop(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateTop(newCube, newCube.map(a => Object.assign({}, a)));
        } else if (rotation == "D'") {
            newCube = rotateBottom(newCube, cubeState);
            newCube = rotateBottom(newCube, newCube.map(a => Object.assign({}, a)));
            newCube = rotateBottom(newCube, newCube.map(a => Object.assign({}, a)));
        }

        setCubeState(newCube);
        let lastTurn = (oldTurns[oldTurns.length - 1]);
        if (lastTurn != (rotation + " ")) {
            setTurns(oldTurns.concat(rotation + " "));
        } else {
            setTurns(oldTurns.slice(0, oldTurns.length - 1).concat(rotation + "2" + " "));
        }

        setStateChanges(stateChanges.concat(rotation))
        console.log(stateChanges);
    }

    function renderSquare(r, c) {
        const parsed_rarity = rarity.toLowerCase();
        if (rarity === "penryn") {
            return (
                <button className={"square " + parsed_rarity + "-" + colors[cubeState[r][c]]} onClick={() => rotate(colorSideMapping[cubeState[r][c]])} >
                    <span className="penryn-text">
                        I waited almost a year and all I got was this nft
                    </span>
                </button >
            );
        } else {
            return(
                <button className={"square " + parsed_rarity + "-" + colors[cubeState[r][c]]} onClick={() => rotate(colorSideMapping[cubeState[r][c]])} >
                    <span className="penryn-text">
                    </span>
                </button >
            )
        }
        return (
            <button className={"square " + rarity + "-" + colors[cubeState[r][c]]} onClick={() => rotate(colorSideMapping[cubeState[r][c]])} >
            </button >
        );
    }

    const renderMovementButtons = () =>
        <div className="top-null">
            <div className="row">
                <div className="col-2">
                    <button onClick={() => rotate("F")} className="pbtn pbtn-primary">F</button>
                    <button onClick={() => rotate("F'")} className="pbtn pbtn-primary">F'</button>
                </div>
                <div className="col-2">
                    <button onClick={() => rotate("B")} className="pbtn pbtn-primary">B</button>
                    <button onClick={() => rotate("B'")} className="pbtn pbtn-primary">B'</button>
                </div>
                <div className="col-2">
                    <button onClick={() => rotate("R")} className="pbtn pbtn-primary">R</button>
                    <button onClick={() => rotate("R'")} className="pbtn pbtn-primary">R'</button>
                </div>
                <div className="col-2">
                    <button onClick={() => rotate("L")} className="pbtn pbtn-primary">L</button>
                    <button onClick={() => rotate("L'")} className="pbtn pbtn-primary">L'</button>
                </div>
                <div className="col-2">
                    <button onClick={() => rotate("U")} className="pbtn pbtn-primary">U</button>
                    <button onClick={() => rotate("U'")} className="pbtn pbtn-primary">U'</button>
                </div>
                <div className="col-2">
                    <button onClick={() => rotate("D")} className="pbtn pbtn-primary">D</button>
                    <button onClick={() => rotate("D'")} className="pbtn pbtn-primary">D'</button>
                </div>
            </div>
        </div>

    const renderViewButtons = () =>
        <div className="row mt-3">
            <div className="col-6">
                <button onClick={() => setx_flip(!x_flip)} className="pbtn pbtn-secondary">Rotate View X</button>
            </div>
            <div className="col-6 text-right">
                <button onClick={() => sety_flip(!y_flip)} className="pbtn pbtn-secondary">Rotate View Y</button>
            </div>
        </div >

    const renderTurns = () =>
        <div>
            <h3>Turns</h3>
            <hr />
            <div className="turns-container">
                {turns}
            </div>
        </div >

    const renderCube = () =>
        <div id="shape" className={(x_flip ? "xflip-" : "") + (y_flip ? "yflip" : "")} >
            <div className="ft">
                <div className="board-row">
                    {renderSquare(0, 0)}
                    {renderSquare(0, 1)}
                    {renderSquare(0, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(0, 3)}
                    {renderSquare(0, 4)}
                    {renderSquare(0, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(0, 6)}
                    {renderSquare(0, 7)}
                    {renderSquare(0, 8)}
                </div>
            </div>
            <div className="rt">
                <div className="board-row">
                    {renderSquare(3, 0)}
                    {renderSquare(3, 1)}
                    {renderSquare(3, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(3, 3)}
                    {renderSquare(3, 4)}
                    {renderSquare(3, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(3, 6)}
                    {renderSquare(3, 7)}
                    {renderSquare(3, 8)}
                </div>
            </div>
            <div className="bk">
                <div className="board-row">
                    {renderSquare(1, 0)}
                    {renderSquare(1, 1)}
                    {renderSquare(1, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(1, 3)}
                    {renderSquare(1, 4)}
                    {renderSquare(1, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(1, 6)}
                    {renderSquare(1, 7)}
                    {renderSquare(1, 8)}
                </div>
            </div>
            <div className="lt">
                <div className="board-row">
                    {renderSquare(2, 0)}
                    {renderSquare(2, 1)}
                    {renderSquare(2, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(2, 3)}
                    {renderSquare(2, 4)}
                    {renderSquare(2, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(2, 6)}
                    {renderSquare(2, 7)}
                    {renderSquare(2, 8)}
                </div>
            </div>
            <div className="tp">
                <div className="board-row">
                    {renderSquare(4, 0)}
                    {renderSquare(4, 1)}
                    {renderSquare(4, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(4, 3)}
                    {renderSquare(4, 4)}
                    {renderSquare(4, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(4, 6)}
                    {renderSquare(4, 7)}
                    {renderSquare(4, 8)}
                </div>
            </div>
            <div className="bm">
                <div className="board-row">
                    {renderSquare(5, 0)}
                    {renderSquare(5, 1)}
                    {renderSquare(5, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(5, 3)}
                    {renderSquare(5, 4)}
                    {renderSquare(5, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(5, 6)}
                    {renderSquare(5, 7)}
                    {renderSquare(5, 8)}
                </div>
            </div>
        </div>



    return (
        <div>
            <div>
                <div className="body m-4">
                    <div className="">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <a href="/">&larr; Your Cubes</a>
                                        <hr></hr>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="left-column-container">

                                            <div id="cube-container">
                                                {renderCube()}
                                            </div>
                                            <div className="control-panel mt-4">
                                                {renderMovementButtons()}
                                                {renderViewButtons()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        {!sendingState && (
                                            <div>
                                                <div className="right-column-container turns-panel">
                                                    {renderTurns()}
                                                </div>
                                                <button onClick={sendState(cubeNFT.cubeIndex, cubeState)} className="pbtn pbtn-primary mt-5">Write to chain</button>
                                            </div>
                                        )}
                                        {sendingState && (
                                            <div className="text-center">
                                                <div className="loading">
                                                    <div className="indicator">
                                                        <LoadingIndicator />
                                                        <p>Writing state to chain...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

};


export default PlayWithCube;
