import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ethers } from 'ethers';
import './index.css';
import LoadingIndicator from './Components/LoadingIndicator';
import GetCube from './Components/GetCube';
import Game from './utils/Game.json';
import MyCubes from './Components/MyCubes';
import PlayWithCube from './Components/PlayWithCube';
import { CONTRACT_ADDRESS, OPENSEA_LINK } from './constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const App = () => {
    // State
    const [currentAccount, setCurrentAccount] = useState(null);
    const [chainId, setChainId] = useState(null);
    const [cubeNFT, setCubeNFT] = useState(null);
    const [cubeCount, setCubeCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [cubeData, setCubeData] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
        [2, 2, 2, 2, 2, 2, 2, 2, 2],
        [3, 3, 3, 3, 3, 3, 3, 3, 3],
        [4, 4, 4, 4, 4, 4, 4, 4, 4],
        [5, 5, 5, 5, 5, 5, 5, 5, 5]
    ]);
    const [colors, setColors] = useState(["white", "white", "white", "white", "white", "white"]);

    const ethereum = window.ethereum;

    // Actions
    const checkIfWalletIsConnected = async () => {
        try {
            const { ethereum } = window;

            if (!ethereum) {
                console.log('Make sure you have MetaMask!');
                setCurrentAccount("unavailable")
                return;
            } else {
                console.log(ethereum);
                const accounts = await ethereum.request({ method: 'eth_accounts' });

                if (accounts.length !== 0) {
                    const account = accounts[0];
                    console.log('Found an authorized account:', account);
                    setCurrentAccount(account);
                } else {
                    console.log('No authorized account found');
                }
            }
        } catch (error) {
            setCurrentAccount("unavailable")
            console.log(error);
        }

        setIsLoading(false);

    };


    function renderSquare(r, c) {
        return (
            <button className={"square " + colors[cubeData[r][c]]} >

            </button >
        );
    }

    const renderCube = () =>
        <div id="shape" className="spin">
            <div className="ft">
                <div className="board-row">
                    {renderSquare(0, 0)}
                    {renderSquare(0, 1)}
                    {renderSquare(0, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(0, 3)}
                    {renderSquare(0, 4)}
                    {renderSquare(0, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(0, 6)}
                    {renderSquare(0, 7)}
                    {renderSquare(0, 8)}
                </div>
            </div>
            <div className="rt">
                <div className="board-row">
                    {renderSquare(3, 0)}
                    {renderSquare(3, 1)}
                    {renderSquare(3, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(3, 3)}
                    {renderSquare(3, 4)}
                    {renderSquare(3, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(3, 6)}
                    {renderSquare(3, 7)}
                    {renderSquare(3, 8)}
                </div>
            </div>
            <div className="bk">
                <div className="board-row">
                    {renderSquare(1, 0)}
                    {renderSquare(1, 1)}
                    {renderSquare(1, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(1, 3)}
                    {renderSquare(1, 4)}
                    {renderSquare(1, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(1, 6)}
                    {renderSquare(1, 7)}
                    {renderSquare(1, 8)}
                </div>
            </div>
            <div className="lt">
                <div className="board-row">
                    {renderSquare(2, 0)}
                    {renderSquare(2, 1)}
                    {renderSquare(2, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(2, 3)}
                    {renderSquare(2, 4)}
                    {renderSquare(2, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(2, 6)}
                    {renderSquare(2, 7)}
                    {renderSquare(2, 8)}
                </div>
            </div>
            <div className="tp">
                <div className="board-row">
                    {renderSquare(4, 0)}
                    {renderSquare(4, 1)}
                    {renderSquare(4, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(4, 3)}
                    {renderSquare(4, 4)}
                    {renderSquare(4, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(4, 6)}
                    {renderSquare(4, 7)}
                    {renderSquare(4, 8)}
                </div>
            </div>
            <div className="bm">
                <div className="board-row">
                    {renderSquare(5, 0)}
                    {renderSquare(5, 1)}
                    {renderSquare(5, 2)}
                </div>
                <div className="board-row">
                    {renderSquare(5, 3)}
                    {renderSquare(5, 4)}
                    {renderSquare(5, 5)}
                </div>
                <div className="board-row">
                    {renderSquare(5, 6)}
                    {renderSquare(5, 7)}
                    {renderSquare(5, 8)}
                </div>
            </div>
        </div>

    const renderContent = () => {
        if (currentAccount && (currentAccount == "unavailable")) {
            return <h1 className="white">Please connect Metamask to continue.</h1>
        } else if (isLoading) {
            return <LoadingIndicator />;
        }

        if (!currentAccount) {
            return (
                <div>
                    <div id="cube-container" className="mb-3">
                        {renderCube()}
                    </div>

                    <h1 className="text-center">Connect your wallet to play.</h1><br></br>
                    <button
                        className="pbtn pbtn-primary btn-limiter"
                        onClick={connectWalletAction}
                    >
                        Connect Wallet
                    </button>

                    <div className="text-center pin-bottom">Contract address: {CONTRACT_ADDRESS}</div>
                </div >
            );
        } else if (currentAccount && chainId != 1) {
            return (
                <div>
                    <div id="cube-container" className="mb-3">
                        {renderCube()}
                    </div>

                    <h1 className="text-center">Unsupported network.<br></br><br></br>Please connect to Ethereum Mainnet</h1>
                </div>
            )
        } else if (currentAccount && !cubeNFT && cubeCount > 0) {
            return (
                <div>
                    <br></br>
                    <MyCubes currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} setCubeNFT={setCubeNFT} />
                    <br></br>
                    <GetCube setCubeNFT={setCubeNFT} cubeCount={cubeCount} setCubeCount={setCubeCount} />
                </div>
            )
        } else if (currentAccount && !cubeNFT && cubeCount == 0) {
            return (
                <div>
                    <center>
                        <div id="cube-container" className="mb-3">
                            {renderCube()}
                        </div>

                        <div className="mb-4">
                            <h1 className="mb-3">You don't have any cubes</h1>
                            
                        </div>
                        <GetCube setCubeNFT={setCubeNFT} cubeCount={cubeCount} setCubeCount={setCubeCount} />
                    </center>
                </div>
            )
        } else if (currentAccount && cubeNFT) {
            return (
                <div>
                    <PlayWithCube cubeNFT={cubeNFT} />
                </div>
            )
        }
    };


    const connectWalletAction = async () => {
        try {
            const { ethereum } = window;

            if (!ethereum) {
                alert('Get MetaMask!');
                return;
            }

            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });

            console.log('Connected', accounts[0]);
            setCurrentAccount(accounts[0]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        checkIfWalletIsConnected();
    }, []);

    useEffect(() => {

        const fetchNFTMetadata = async () => {
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });

            setCurrentAccount(accounts[0]);

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const network = await provider.getNetwork();
            setChainId(network.chainId);
            const signer = provider.getSigner();
            const gameContract = new ethers.Contract(
                CONTRACT_ADDRESS,
                Game.abi,
                signer
            );

            const txn = await gameContract.tokensOfOwner(currentAccount);
            setCubeCount(txn.length);
            console.log(txn);
            setIsLoading(false);
        };

        if (currentAccount) {
            console.log('CurrentAccount:', currentAccount);
            fetchNFTMetadata();
        }

    }, [currentAccount]);


    return (
        <div className="App">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand text-black" href="#">
                        On Chain Cubes
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav ms-auto">
                            <a className="nav-link active" aria-current="page" href="https://twitter.com/0x61656c">Twitter</a>
                            <div>*</div>
                            <a className="nav-link active" aria-current="page" href="https://github.com/0x61656c">Github</a>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="body m-4">
                <div className="connect-wallet-container">
                    {renderContent()}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default App;

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
